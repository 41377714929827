<template>
  <div>
    
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="card-base-pdf" >
              <v-card-text class="text--primary text-subtitle-1">
                  Welcome to User Manual Module. Click <a
                      href="/MiQIMS Dokumen Manual Pengguna Sistem v1.0.pdf"
                      target="_blank">here</a> to download/open at the new tab.
              </v-card-text>
              <v-card-text>
                  <embed
                  src="/MiQIMS Dokumen Manual Pengguna Sistem v1.0.pdf#toolbar=0"
                  type="application/pdf"
                  class="manual-embed"
                  />
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
// -- import something here --

export default {

  data: () => ({
  }),

  mounted() {
    console.log("welcome home");
  },

  methods: {
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.manual-embed{
    height: calc(100vh - 270px);
    // height: 100vh;
    width: 100%;
}

</style>


